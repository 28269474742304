import React from 'react';
import { useSelector } from 'react-redux';
import { platformSelectors } from '@clatter/platform';

const useContentRepository = (contentRepoId) => {
  const contentRepositories = useSelector(
    platformSelectors.contentRepositories.selectAllContentRepositories,
  );

  const selectedContentRepository = useSelector(
    platformSelectors.contentRepositories.selectSelectedContentRepository,
  );

  const loadingStatus = useSelector(
    ({ platform: { contentRepositories } }) =>
      contentRepositories.loadingStatus,
  );

  return { contentRepositories, selectedContentRepository, loadingStatus };
};

export default useContentRepository;
